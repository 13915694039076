document.addEventListener("DOMContentLoaded", function () {
    if (typeof (cookieconsent) !== 'undefined') {
        cookieconsent.run({
            notice_banner_type: "simple", // simple | headline | interstitial | standalone
            consent_type: "express",
            palette: "dark",
            language: "de",
            page_load_consent_levels: ["strictly-necessary"],
            notice_banner_reject_button_hide: false,
            preferences_center_close_button_hide: false,
            page_refresh_confirmation_buttons: false,
            website_name: "Vereinigung Wasserkraftwerke in Bayern e.V.",
            website_privacy_policy_url: "/datenschutz"
        });
    }
});
